<template>
	<el-dialog title="培训报名名单" :visible.sync="showActivityVisible" width="800px" center append-to-body :close-on-click-modal="false">
		<Table
			:loading="loading"
			:table-data="tableData"
			:total="total"
			:table-head="tableHead"
			:current-page.sync="queryData.current"
			:page-size.sync="queryData.size"
			@change-page="getShowList"
			:tableHeight="tableHeight"
			:showOperation="false"
		>
		</Table>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="showActivityVisible = false">关 闭</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			showActivityVisible: false,
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '报名人',
					prop: 'name',
					formatter: function (row) {
						return row.name || '-';
					},
				},
				{
					label: '联系方式',
					prop: 'phoneNumber',
					formatter: function (row) {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '价格',
					prop: 'price',
					formatter: function (row) {
						return row.price ? (row.price / 100).toFixed(2) + '元' : '0';
					},
				},
				{
					label: '报名时间',
					prop: 'createTime',
					width: 160,
					formatter: function (row) {
						return row.createTime || '-';
					},
				},
			],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 410;
		},
	},
	mounted() {},
	methods: {
		//获取列表
		getShowList(params) {
			console.log(params);
			this.loading = true;
			this.$http
				.get(this.api.getLecturePaymentRights, {
					params: {
						...this.queryData,
						lecturePaymentId: params.id,
					},
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.tableData = res.data.collection || [];
						this.total = res.data.pagination.totalItems || 0;
						this.loading = false;
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .page-box {
	height: 50px;
	margin-top: 10px;
}
</style>